import React from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import './App.css';
import Loading from './components/Loading';

//const loading = () => <div style={{position: 'absolute', width: '100%', height: '100%', backgroundColor: 'white'}}></div>;
const loading = <Loading init show={true}/>
const DefaultContainer = React.lazy(() => import('./containers/DefaultContainer'));

class App extends React.Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route path="/" name="Home" render={props => <DefaultContainer {...props}/>} />
            {/*<Route exact path="/new" name="Cadastro" render={props => <CadastroContainer {...props}/>} />*/}
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }  
}

export default App;
