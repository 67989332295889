import React from 'react';
import logo from './../assets/img/logo.png';

const Loading = (props) => {

    const style = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        zIndex: '1000',
        visibility: props.show ? 'visible' : 'hidden',
        backgroundColor: props.init ? ( props.init == true ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0.6)') : 'rgba(255,255,255,0.6)',
        animation: '2s visibility',
        transition: 'background-color 0.5s ease-out, visibility 1s ease-out'
    };

   /* return(
        <div className="d-flex justify-content-center" style={style}>
            <div className="d-flex flex-column justify-content-center">
                <h1>Aguarde...</h1>
            </div>            
        </div>
    );*/

    return(
        <div className="d-flex justify-content-center" style={style}>
            <div className="d-flex flex-column justify-content-center">
                <img alt="" src={logo} width={90} className="loadAnimation"/>
            </div>            
        </div>
    );

}

export default Loading;